<template>
  <div class="h-backgroud-color_body">
    <HYList ref="HYList" v-bind="listConfig">
      <template #columnHead="{ row }">
        <div class="row">
          <div class="row-head h-flex h-row-between">
            <span>销售日期：{{ row.saleDate && dayjs(row.saleDate).format("YYYY-MM-DD") }}</span>
            <!-- <span class="h-primary-color">{{row.agreement | dictFormat("CONTRACT_SIGN_STATUS")}}</span> -->
          </div>
          <div class="row-content" :class="`status${row.contractStatus}`">
            <div class="h-font-md h-flex">
              <span class="row-content_title">合同号：</span>
              <span>{{ row.contractNo }}</span>
            </div>
            <div class="h-font-md h-flex">
              <span class="row-content_title">客户名称：</span>
              <span>{{ row.customerName }}</span>
            </div>
            <div class="h-font-md h-flex">
              <span class="row-content_title">车架号：</span>
              <span>{{ row.frameNo }}</span>
            </div>
            <div class="h-font-md h-flex">
              <span class="row-content_title">服务类型：</span>
              <span>{{ row.productNames }}</span>
            </div>
          </div>
          <div class="row-foot h-flex h-row-right">
            <!-- <van-button v-if="row.agreement==='0'"  type="primary" size="small" @click="showSign(row)">分享签署</van-button> -->
            <van-button type="primary" size="small" @click="toDetailPdf(row)">查看</van-button>
          </div>
        </div>
      </template>
      <template #empty>
        <div class="none-tip">
          <van-image :src="require('@/assets/images/none_data.jpg')" />
          <div>无查询信息！</div>
        </div>
      </template>
    </HYList>
    <van-dialog v-model="pageData.show" close-on-click-overlay confirm-button-text="关闭" style="max-heigh:80%">
      <div class="h-text-center h-p-10">
        <img :src="imgUrl()" />
        <div class="h-primary-color">长按二维码,分享给客户签署合同</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import HYList from "@/components/List/index";
import dayjs from "dayjs";
export default {
  name: "ContractList",
  components: { HYList },
  props: {
    name: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      pageData: {
        show: false
      },
      listConfig: {
        styleObj: {
          minHeight: null
        },
        api: {
          url: "/afis-carservice/contract/list"
        },
        form: {
          // terminal:"3",//移动端
          unionQuery: this.name,
          contractStatus: this.type
        },
        column: []
      }
    };
  },
  watch: {
    name: function(val) {
      this.listConfig.form.unionQuery = this.name;
      this.onSearch();
    }
  },
  methods: {
    dayjs,
    imgUrl() {
      if (process.env && process.env.NODE_ENV === "production") {
        return require("./contractImg/sign_pro.png");
      } else {
        return require("./contractImg/sign_dev.png");
      }
    },
    onSearch() {
      this.$refs.HYList.onRefresh();
    },
    toDetail(row) {
      this.$router.push({
        path: "/product/contract/detail",
        query: {
          id: row.id,
          productCode: row.productCodes
        }
      });
    },
    toDetailPdf(row) {
      this.$router.push({
        path: "/product/contract/detailPdf",
        query: {
          id: row.id
        }
      });
    },
    showSign(row) {
      this.pageData.show = true;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.row {
  padding: 10px 0;
  &-head {
    font-size: $font-size-lg;
    padding: 6px 20px;
    position: relative;
    &::after {
      @include hairline-bottom($border-color, $padding-xs, $padding-xs, $border-width-xs);
    }
  }
  &-content {
    padding: 6px 20px;
    background-repeat: no-repeat;
    background-position: 90% center;
    background-size: 70px;
    div {
      padding: 2px 0;
    }
    &_title {
      color: #8c8c8c;
      white-space: nowrap;
      text-align: right;
    }
  }
  //未生效
  .status0 {
    background-image: url("./contractImg/status_0.svg");
  }
  //有效
  .status1 {
    background-image: url("./contractImg/status_1.svg");
  }
  //终止
  .status9 {
    background-image: url("./contractImg/status_9.svg");
  }
  &-foot {
    border-top: 2px solid $border-color;
    padding: 6px 20px;
    .van-button {
      margin-right: 5px;
    }
  }
}
.none-tip {
  margin: 10px;
  border-radius: 5px;
  background-color: $white;
  ::v-deep .van-image {
    margin-top: 20px;
  }
}
::v-deep .van-pull-refresh {
  min-height: calc(100vh - 98px);
}
</style>
